import IconPurchase from 'components/LogoMenu/iconPurchase';
import IconOffering from 'components/LogoMenu/iconOffering';
import IconCampaigns from 'components/LogoMenu/iconCampaigns';
import { useTranslation } from 'react-i18next';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const MenuItemsDtc = () => {
  const { t } = useTranslation();
  const menuItemsDtc: MenuItems[] = [
    {
      heading: t("digital-tenis-comunity"),
      items: [
        {
          name: t("purchase-requests"),
          link: '/purchase_requests',
          icon: IconPurchase
          
        },
        {
          name: t("product-offering"),
          link: '/Product_offering',
          icon: IconOffering
        },
      ]
    },
    {
      heading: t("management"),
      items: [
        {
          name: t('campaigns'),
          link: '/campaigns',
          icon: IconCampaigns
        }
      ]
    },
  ];

  return menuItemsDtc;
};

export default MenuItemsDtc;
