import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { inactiveOfferClubForm, valueOfferContext } from 'content/applications/constans/offer.interface';
import api from 'services/api';
import { useAuth } from './AuthContext';
import { useAdmin } from './adminContext';
import { noticeConfirmation } from 'content/notices/notices';
import { useTranslation } from 'react-i18next';

export const offerContext = createContext<valueOfferContext>({
  createOffer: (_sport: String, _name: String, _module: String, _product: String, _price: Number, _campaign: String,
    _audience: String, _startDate: String, _finishDate: String, _status: String, _eventID: String,
    _eventName: String, _description: String, _clubID: String) => {},
  loading: false,
  updateOffer: (_uid: String, _sport: String, _name: String, _module: String, _product: String, _price: Number,
    _audience: String, _startDate: String, _finishDate: String, _status: String,
    _eventID: String, _eventName: String, _description: String, _clubID: String) => {},
  updateStatusOffer: (_uid: String, _status: String, _clubID: String) => {}, 
  listOffers: (_clubId: String) => {},
  inactiveOffer: (_values: inactiveOfferClubForm) => null,
  getOffer: (_offerId: String) => {},
  error: null,
  list: [],
  offerID: null,
  offer: null,
  productOffer: null,
  stateF: null,
  stateR: null
});

export const useOffer = () => {
  const context = useContext(offerContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function OfferProvider({ children }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [offerID, setOfferID] = useState('');
  const [productOffer, setProductOffer] = useState('');
  const [offer, setOffer] = useState({
    active: true, name: '', module: '', product: '', startDate: '', finishDate: '',
    audience: '', price: 0, clubID: '', campaign: '', status: '', eventID: '',
    eventName: '', description: '', sport: ''});
  const [stateF, setStateF] = useState(true); 
  const [stateR, setStateR] = useState(true); 
  const { token,user } = useAuth();
  const { clubID } = useAdmin();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const role = userObject ? userObject.role : null;

  const createOffer = async (
    sport: String, name: String, module: String, product: String, price: Number, campaign: String,
    audience: String, startDate: String, finishDate: String, status: String, eventID: String,
    eventName: String, description: String, clubID: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/sponsors/club/offer/create',
            data: { sport, name, module, product, price, campaign, audience, startDate, finishDate,
              status, eventID, eventName, description, clubID },
            headers: {"Authorization": token}
          });
          noticeConfirmation(
            t("text-notification-create-offer-1"),
            t("text-notification-create-offer-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        } catch (error) {
          setError(error);
          noticeConfirmation(
            t("text-notification-create-offer-3"),
            t("text-notification-create-offer-4"),
            "warning",
            "red",
            t("text-button-ok")
          );
        }
      } finally {
        setLoading(false);
      };
  };

  const updateOffer = async (
    uid: String, sport: String, name: String, module: String, product: String, price: Number,
    audience: String, startDate: String, finishDate: String, status: String, eventID: String,
    eventName: String, description: String, clubID: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/sponsors/club/offer/update',
            data: { uid, sport, name, module, product, price, audience, startDate, finishDate,
              status, eventID, eventName, description, clubID },
            headers: {"Authorization": token}
          });
          noticeConfirmation(
            t("text-notification-update-offer-1"),
            t("text-notification-update-offer-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        } catch (error) {
          setError(error);
          noticeConfirmation(
            t("text-notification-update-offer-3"),
            t("text-notification-update-offer-4"),
            "warning",
            "red",
            t("text-button-ok")
          );
        }
      } finally {
        setLoading(false);
      };
  };

  const updateStatusOffer = async (
    uid: String, status: String, clubID: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/sponsors/club/offer/update',
            data: { uid, status, clubID },
            headers: {"Authorization": token}
          });
        } catch (error) {
          setError(error); 
        }
      } finally {
        setLoading(false);
      };
  };

  const getOffer = async (id: String) => {
    setLoading(true)
    try {
        try {
          const res = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/sponsors/club/offer/${id}`,
            headers: {"Authorization": token}
          }).then(response => {
            setOfferID(response.data.response.id);
            setProductOffer(response.data.response.product);
            setOffer(response.data.response);
            
            if(response.data.response.product == 'Footers'){
              setStateF(false);
              setStateR(true);
            }
            if(response.data.response.product == 'Name Right'){
              setStateF(true);
              setStateR(false);
            }
            if(response.data.response.product == 'Name Right And Footers'){
              setStateF(false);
              setStateR(false);
            }
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const listOffers = async (id: String) => {
    setLoading(true)
    try {
        try {
          const res = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/sponsors/club/offers/${id}`,
            headers: {"Authorization": token}
          }).then(response => {
            setList(response.data.response);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const inactiveOffer = async (values: inactiveOfferClubForm) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/sponsors/club/offer/inactivate/:id',
          });
          return 
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  // useEffect(() => {
  //   if (token && role === "admin") {
  //     listOffers(clubID);
  //   };
  // },[token, user, clubID])

  
  return (
    <offerContext.Provider
      value={{
        createOffer,
        loading,
        updateOffer,
        updateStatusOffer,
        listOffers,
        inactiveOffer,
        getOffer,
        error,
        list,
        offerID,
        offer,
        productOffer,
        stateF,
        stateR,
      }}
    >
      {children}
    </offerContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
