import Swal from "sweetalert2";
import logo from 'components/LogoSign/TappS_blueLogo.png';

export type IconStatus = "success" | "warning" | "question" | "error" | "info";

export const noticeConfirmation = (
    title: string, text: string, icon: IconStatus, iconColor: string, textButton) => 
{
    Swal.fire({
        title: title,
        text: text,
        color: "black",
        imageUrl: logo,
        imageHeight: 100,
        imageWidth: 100,
        confirmButtonColor: "#3C1C91",
        confirmButtonText: textButton,
        background: "#C9F305",
        icon: icon,
        iconColor: iconColor
      }); 
}