import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Importa las traducciones
import esTranslations from '../languages/es.json';
import enTranslations from '../languages/en.json';
import arTranslations from '../languages/ar.json';
import deTranslations from '../languages/de.json';
import elTranslations from '../languages/el.json';
import frTranslations from '../languages/fr.json';
import hiTranslations from '../languages/hi.json';
import itTranslations from '../languages/it.json';
import jaTranslations from '../languages/ja.json';
import nlTranslations from '../languages/nl.json';
import ptTranslations from '../languages/pt.json';
import zhTranslations from '../languages/zh.json';

i18n
  .use(initReactI18next)
  .init({
    resources: {
      es: {
        translation: esTranslations,
      },
      en: {
        translation: enTranslations,
      },
      ar: {
        translation: arTranslations,
      },
      de: {
        translation: deTranslations,
      },
      el: {
        translation: elTranslations,
      },
      fr: {
        translation: frTranslations,
      },
      hi: {
        translation: hiTranslations,
      },
      it: {
        translation: itTranslations,
      },
      ja: {
        translation: jaTranslations,
      },
      nl: {
        translation: nlTranslations,
      },
      pt: {
        translation: ptTranslations,
      },
      zh: {
        translation: zhTranslations,
      }
    },
    lng: 'en', // Idioma predeterminado
    fallbackLng: 'en', // Idioma de respaldo en caso de no encontrar una traducción
    interpolation: {
      escapeValue: false, // Permite el uso de HTML en las traducciones
    },
  });

export default i18n;
