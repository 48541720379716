import { Box, Container, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';

const FooterWrapper = styled(Box)(
  ({ theme }) => `
        border-radius: 0;
        margin: ${theme.spacing(3)} 0;
`
);

function Footer() {
  const { t } = useTranslation();
  return (
    <FooterWrapper>
      <Container maxWidth="lg">
        <Box
          py={3}
          display={{ xs: 'block', md: 'flex' }}
          alignItems="center"
          textAlign={{ xs: 'center', md: 'left' }}
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="subtitle1" sx={{ mt: { color: "#ffff" } }}>
              &copy; 2023 - {t("sponsor")} {t("for")} RacquetsAppSuite
            </Typography>
          </Box>
          <Typography sx={{ pt: { xs: 2, md: 0, color: "#ffff" } }} variant="subtitle1">
            {t("crafted")} <Link href="https://racquetsappsuite" target="_blank" rel="noopener noreferrer">racquetsappsuite</Link>
          </Typography>
        </Box>
      </Container>
    </FooterWrapper>
  );
}

export default Footer;
