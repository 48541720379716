import { createContext, useContext, useState } from 'react';
import axios from 'axios';
import { useAuth } from './AuthContext';
import { valuePurchaseContext } from 'content/applications/constans/request.interface';
import { useOffer } from './OfferingContext';
import { useCampaign } from './CampaignsContext';

export const purchaseContext = createContext<valuePurchaseContext>({
  loading: false,
  error: null,
  getPurchaseRequest: (_requestId: String) => {},
  purchase: null,
});

export const usePurchase = () => {
  const context = useContext(purchaseContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function PurchaseProvider({ children }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { getOffer } = useOffer();
  const [purchase, setPurchase] = useState({
    sponsor: {
      banner: '', company: '', admin: '', city: '', active: false, country: '', company_description: '',
      industry: '', avatar: '', phone: 0, position_admin: '', id: ''
    },
    request: {
      clubID: '', sponsorName: '', sponsorID: '', right: {headline: ''},
      footer: {
        tag: '', image: '', line: '', headline: '', link: ''},
      active: false, idOffer: '', created: '', status: '', id: ''},
    requestByYearWithClub: 0,
    accumulativeWithClub: 0,
    requestByYearWithAll: 0,
    accumulativeWithAll: 0,
  });
  const { token,user } = useAuth();
  const { getCampaignsByOffer } = useCampaign();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const role = userObject ? userObject.role : null;
  
  const getPurchaseRequest = async (id: String) => {
    setLoading(true)
    try {
      try {
        const res = await axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/request-purshase/${id}`,
          headers: { "Authorization": token }
        }).then(response => {
          setPurchase(response.data.response);
          getOffer(response.data.response.request.idOffer);
          getCampaignsByOffer(response.data.response.request.idOffer);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  return (
    <purchaseContext.Provider
      value={{
        loading,
        error,
        getPurchaseRequest,
        purchase,
      }}
    >
      {children}
    </purchaseContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
