import { Box, Button, Divider, Grid, MenuItem, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { usePayment } from "contexts/PaymentContext";
import { useAuth } from "contexts/AuthContext";
import { useClub } from "contexts/ClubContext";
import Swal from "sweetalert2";
import logo from 'components/LogoSign/TappS_blueLogo.png';
import { useTranslation } from "react-i18next";

function PaymentConfirmation() {
  const { t } = useTranslation();  
  const { information, distributedPayment } = usePayment();
  const [customer, setCustomer] = useState('');
  const [cardID, setCardID] = useState('');
  const { user } = useAuth();
  const { club } = useClub();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
    ? //@ts-ignore
      JSON.parse(user?.reloadUserInfo?.customAttributes)
    : null;
  const cards = userObject ? userObject.cards: null;
  const clubName = club ? club.name: null;
  const clubIdStripe = club ? club.connectedAccountIdStripe: null;

  const handleChange = (e) => {
    const cardData = cards.find(item => item.CardID == e.target.value);
    setCustomer(cardData.Customer);
    setCardID(cardData.CardID);
  }
  
  const handleSubmit = async () => {
      Swal.fire({
        title: t("text-notification-question-1"),
        text: t("text-notification-question-payment-1"),
        color: "black",
        imageUrl: logo,
        imageHeight: 100,
        imageWidth: 100,
        showDenyButton: true,
        confirmButtonColor: "#3C1C91",
        denyButtonColor: "#00E392",
        confirmButtonText: t("text-button-ok"),
      denyButtonText: t("text-button-no"),
        background: "#C9F305",
      }).then(response => {
        if(response.isConfirmed){
        const description = t("text-notification-confirmed-payment-1") + 
          information.product + t("of") + " " + information.module + ", (" + information.sport + ")" +
          t("text-notification-confirmed-payment-2") + clubName + ".";
        distributedPayment(information.price, information.currency, description, cardID,
          customer, clubIdStripe, 70, information.campaignID);
      }
    })
  }

  return (
    <Grid>
      <h2 className="text-center">{t("payment-sumary")}</h2>
      <Divider />
      <Grid sx={{display: '-webkit-flex', flexDirection: 'row', marginBottom: 1,
        backgroundColor: '#c1c7c3'}}>
        <Grid sx={{flex: 1, padding: 2}}>
          <Grid sx={{flex: 1, display: '-webkit-flex', flexDirection: 'row'}}>
            <Typography variant="h4" gutterBottom sx={{flex: 1}}>
              {t("product")}:
            </Typography>
            <Typography variant="h4" gutterBottom sx={{flex: 2, fontWeight: 300}}>
              {information.product} 
            </Typography>
          </Grid>
          <Grid sx={{flex: 1, display: '-webkit-flex', flexDirection: 'row'}}>
            <Typography variant="h4" gutterBottom sx={{flex: 1}}>
              {t("app")}:
            </Typography>
            <Typography variant="h4" gutterBottom sx={{flex: 2, fontWeight: 300}}>
              {information.module} 
            </Typography>
          </Grid>
          <Grid sx={{flex: 1, display: '-webkit-flex', flexDirection: 'row'}}>
            <Typography variant="h4" gutterBottom sx={{flex: 1}}>
              {t("sport")}:
            </Typography>
            <Typography variant="h4" gutterBottom sx={{flex: 2, fontWeight: 300}}>
              {information.sport} 
            </Typography>
          </Grid>
        </Grid>
        <Grid sx={{flex: 1, padding: 2}}>
          <Grid sx={{flex: 1, display: '-webkit-flex', flexDirection: 'row'}}>  
          <Typography variant="h4" gutterBottom sx={{flex: 1}}>
            {t("amount")}:
          </Typography>
          <Typography variant="h4" gutterBottom sx={{flex: 2, fontWeight: 300}}>
            {information.price}
          </Typography>
          </Grid>
          <Grid sx={{flex: 1, display: '-webkit-flex', flexDirection: 'row'}}>
          <Typography variant="h4" gutterBottom sx={{flex: 1}}>
            {t("currency")}:
          </Typography>
          <Typography variant="h4" gutterBottom sx={{flex: 2, fontWeight: 300}}>
            {information.currency}
          </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid sx={{padding: 2}}>
        <Typography variant="h4" component="h4" gutterBottom>
          {t("description-payment")}: {t("text-pay-1")} {information.product} {t("of")} 
          {information.module} ({information.sport}) {t("text-pay-2")} {clubName}.
        </Typography>
      </Grid>
      <Divider/>
      <Grid item xs={12}>           
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' }
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-select-card"
              select
              label={t("payment-card")}
              onChange={handleChange}
              helperText={t("text-select-card")}
            >
              {cards.map((option) => (
                <MenuItem key={option.CardID} value={option.CardID}>
                  ...{option.Last4} {option.Brand} 
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Box>
      </Grid>
      <Grid>
        <Button
          size="large"
          sx={{ margin: 1, marginTop: 5, background: '#3C1C91', paddingInline: 10,
              backgroundColor: '#00E392', color: '#1A2660', borderRadius: 30 }}
          variant="outlined"
          onClick={handleSubmit}
        >
          {t("pay")}
        </Button>
      </Grid>
    </Grid>
  );
}

export default PaymentConfirmation;
