import { Box, Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import logo from './LogoTapp.png';

const LogoWrapper = styled(Link)(
  ({ theme }) => `
        color: ${theme.palette.text.primary};
      
        text-decoration: none;
        width: 53px;
        
        font-weight: ${theme.typography.fontWeightBold};

        margin: 'auto',
        textAlign: 'center',

        Align-Items: center;
        Justify-Content: center;
        alignSelf: 'center';
 
`
);

function Logo() {

  return (
    <Tooltip title="Tapp Suite" arrow>
      <LogoWrapper to="/overview">       
        <img src={logo}  width="150" height="60" alt="logo" />
      </LogoWrapper>
    </Tooltip>
  );
}

export default Logo;
