import { Tooltip } from '@mui/material';
import logo from './iconProduct.png';

function IconProduct() {
  return (
    <Tooltip title="" arrow>
        <img src={logo} width="40" height="40" alt="logo" />
    </Tooltip>
  );
}

export default IconProduct;