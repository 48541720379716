import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';

import SidebarLayout from 'layouts/SidebarLayout';
import NavbarLayout from 'layouts/NavbarLayout';

import SuspenseLoader from 'components/SuspenseLoader';
import StatusLayout from 'layouts/StatusLayout';
import { ProtectedRoute } from 'navigation/protected-routes';
import PaymentMode from 'content/applications/Payment/PaymentMode';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Applications
const Terms = Loader(lazy(() => import('content/applications/Users/Terms')));
const SignIn = Loader(lazy(() => import('content/signin/sign-in.component')));
const SignUp = Loader(lazy(() => import('content/signup/sign-up.component')));
const Payment = Loader(lazy(() => import('content/applications/Payment')));
const CampaignsSponsor = Loader(
  lazy(() => import('content/applications/Transactions/CampaignsSponsor'))
);
const RecoveryEmail = Loader(
  lazy(() => import('content/applications/Configurations/RecoveryEmail'))
);
const Password = Loader(
  lazy(
    () =>
      import(
        'content/applications/Configurations/PasswordModification/PasswordRecovery'
      )
  )
);
const ProductRequest = Loader(
  lazy(
    () =>
      import(
        'content/applications/Transactions/ProductRequest'
      )
  )
);
const UserProfile = Loader(
  lazy(() => import('content/applications/Users/profile'))
);
const UserSettings = Loader(
  lazy(() => import('content/applications/Users/settings'))
);
const Status404 = Loader(lazy(() => import('content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('content/pages/Status/Status500')));
const StatusMaintenance = Loader(
  lazy(() => import('content/pages/Status/Maintenance'))
);

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <NavbarLayout />,
    children: [
      {
        path: 'signin',
        element: <SignIn />
      },
      {
        path: 'signup',
        element: <SignUp />
      },
      {
        path: 'recovery_Email',
        element: <RecoveryEmail />
      },
      {
        path: 'password_recovery',
        element: <Password />
      },
      {
        path: 'terms_and_conditions',
        element: <Terms />
      }
    ]
  },
  {
    path: '*',
    element: <StatusLayout />,
    children: [
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/',
    element: (
      <ProtectedRoute role="sponsor">
        <SidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: '/campaigns',
        element: <CampaignsSponsor />
      },
      {
        path: 'product_request',
        element: <ProductRequest />
      },
      {
        path: 'payment',
        element: <Payment />
      },
      {
        path: 'payment_mode',
        element: <PaymentMode />
      },
      {
        path: 'profile',
        children: [
          {
            path: 'details',
            element: <UserProfile />
          },
          {
            path: 'settings',
            element: <UserSettings />
          }
        ]
      }
    ]
  }
];

export default routes;
