import { createContext, useContext, useEffect, useState } from 'react';
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  sendPasswordResetEmail,
  updateProfile,
  User
} from 'firebase/auth';
import { auth } from '../config/firebase-config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  signinForm,
  signUpForm,
  valueAuthContext
} from 'content/signup/constants/sign-up.interfaces';
import { useAlert } from 'react-alert';
import { noticeConfirmation } from 'content/notices/notices';
import api from 'services/api';
import { useTranslation } from 'react-i18next';

export const authContext = createContext<valueAuthContext>({
  login: (_values: signinForm) => {},
  loading: false,
  user: null,
  logout: () => {},
  signup: (_values: signUpForm) => {},
  updateUser: (_uid: string, _role: string, _email: string, _approved: string,
    _firstLaunch: string, _city: string, _country: string, _firstname: string,
    _lastame: string, _phone: string) => {},
  updatePhotoURL: (_newUser: User, _displayName: string, _photoURL: string) => {},
  error: null,
  token: null,
  resetPassword: (email: string) => {},
  getUsers: () => {},
  users: [],
  accept: false,
  setAccept: () => {},
});

export const useAuth = () => {
  const context = useContext(authContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function AuthProvider({ children }) {
  const { t } = useTranslation();
  const alert = useAlert();
  const [user, setUser] = useState(() => {
    const user = auth.currentUser;
    return user;
  });
  const [token, setToken] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);
  const [accept, setAccept] = useState(false);

  const login = async (email: string, password: string) => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const signup = async (values: signUpForm) => {
    setLoading(true);
    try {
      try {
        const res = await axios({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/register',
          data: values
        });
        if (res.data.ok) {
          noticeConfirmation(
            t("text-notification-signup-1"),
            t("text-notification-signup-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
          navigate('/signin');
        }
        if (!res.data.ok) {
          noticeConfirmation(
            t("text-notification-signup-3"),
            t("text-notification-signup-4"),
            "error",
            "red",
            t("text-button-ok")
          );
        }
      } catch (error) {
        noticeConfirmation(
          t("text-notification-signup-5"),
          t("text-notification-signup-6"),
          "warning",
          "red",
          t("text-button-ok")
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const logout = () => signOut(auth);

  const getUsers = async () => {
    setLoading(true)
    try {
      api({
        method: 'get',
        url: process.env.REACT_APP_API_URL + `/users/all/users`
      }).then(response => {
        setUsers(response.data.response.users)
      })
    } catch (error) {
      setError(error);
    }
  };

  const updateUser = async (uid: string, role: string, email: string, approved: string,
    firstLaunch: string, city: string, country: string, firstname: string,
    lastname: string, phone: string) => {
    try {
      try {
        const res = await axios({
          method: 'post',
          url: process.env.REACT_APP_API_URL +'/users/update',
          data: { uid, role, email, approved, firstLaunch, city, country, firstname,
            lastname, phone }
        });
        if (res.data.ok) {
          noticeConfirmation(
            t("text-notification-update-user-1"),
            user.displayName + ", " + t("text-notification-update-user-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        }
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    }
  };

  const updatePhotoURL = async (newUser: User, displayName: string, photoURL: string) => {
    await updateProfile(newUser, {displayName, photoURL});
  };

  const resetPassword = async (email: string) => {
    sendPasswordResetEmail(auth, email).then(() => {
      noticeConfirmation(
        t("text-notification-reset-password-1"),
        user.displayName + ", " + t("text-notification-reset-password-2"),
        "success",
        "#00E392",
        t("text-button-ok")
      );
    })};

  useEffect(() => {
    const unsubuscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
      setLoading(false);
      const token = auth.currentUser;
    });
    return () => unsubuscribe();
  }, []);

  useEffect(() => {
    if (user) {
      auth.currentUser
        .getIdToken(/* forceRefresh */ true)
        .then(function (idToken) {
          setToken(idToken);
        })
        .catch(function (error) {
          // Handle error
        });
    }
  }, [user]);

  useEffect(() => {
    getUsers();
  },[]);

  console.log('token ', token);

  return (
    <authContext.Provider
      value={{
        login,
        user,
        logout,
        loading,
        signup,
        updateUser,
        updatePhotoURL,
        error,
        token,
        resetPassword,
        getUsers,
        users,
        accept,
        setAccept,
      }}
    >
      {children}
    </authContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
