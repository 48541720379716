//@ts-nocheck
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';

import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { AuthProvider } from 'contexts/AuthContext';
import { positions, Provider } from 'react-alert';
import AlertMUITemplate from 'react-alert-template-mui';
import { OfferProvider } from 'contexts/OfferingContext';
import EntryPoint from 'entry';
import { LocationProvider } from 'contexts/LocationContext';
import { AdminProvider } from 'contexts/adminContext';
import { ClubProvider } from 'contexts/ClubContext';
import { CampaignProvider } from 'contexts/CampaignsContext';
import { RequestProvider } from 'contexts/RequestContext';
import { PurchaseProvider } from 'contexts/PurchaseContext';
import { PaymentProvider } from 'contexts/PaymentContext';
import { StripeProvider } from 'react-stripe-elements';
import StripeScriptLoader from 'react-stripe-script-loader';
import { DatesAppProvider } from 'contexts/DatesAppContext';
import './config/i18n';
import { EmailProvider } from 'contexts/emailContext';

const options = {
  position: positions.BOTTOM_CENTER,
  timeout: 5000,
  offset: '30px'
};

const App = () => {
  return (
    <Provider template={AlertMUITemplate} {...options}>
      <AuthProvider>
        <DatesAppProvider>
          <LocationProvider>
            <AdminProvider>
              <ClubProvider>
                <OfferProvider>
                  <RequestProvider>
                    <CampaignProvider>
                      <PurchaseProvider>
                        <StripeScriptLoader
                          //uniqueId='stripe-script'
                          script="https://js.stripe.com/v3/"
                          loader=""
                        >
                          <StripeProvider
                            apiKey={process.env.REACT_APP_STRIPE_PUBLIC}
                          >
                            <PaymentProvider>
                              <EmailProvider>
                              <ThemeProvider>
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                >
                                  <CssBaseline />
                                  <EntryPoint />
                                </LocalizationProvider>
                              </ThemeProvider>
                              </EmailProvider>
                            </PaymentProvider>
                          </StripeProvider>
                        </StripeScriptLoader>
                      </PurchaseProvider>
                    </CampaignProvider>
                  </RequestProvider>
                </OfferProvider>
              </ClubProvider>
            </AdminProvider>
          </LocationProvider>
        </DatesAppProvider>
      </AuthProvider>
    </Provider>
  );
};
export default App;
