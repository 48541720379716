import { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { useAuth } from './AuthContext';
import { valueCampaignContext } from 'content/applications/constans/campaign.interface';
import { useAdmin } from './adminContext';

export const campaignContext = createContext<valueCampaignContext>({
  loading: false,
  createCampaign: (_offer_id: String, _sport: String, _name: String, _module: String, _product: String, _startDate: String,
    _finishDate: String, _audience: String, _price: Number, _status: String, _currency: String,
    _eventName: String, _clubID: String, _sponsorID:String) => {},
  getCampaignsByClub: (_id: String) => {},
  getCampaignsBySponsor: (_admin: String) => {},
  getCampaignsPendingPaymentBySponsor: (_id: String) => {},
  updateCampaign: (_uid: String, _offer_id: String, _sport: String, _name: String, _module: String,
    _product: String, _startDate: String, _finishDate: String, _audience: String,
    _price: number, _status: String, _currency: String, _eventName: String, _clubID: String, _sponsorName: String,
     _sponsorID: String) => {},
  getCampaignsByOffer: (_id: String) => {},
  campaigns: [],
  campaignsSponsor: [],
  campaignsClub: [],
  campaignsForPayment: [],
  purchases: [],
  error: null,
});

export const useCampaign = () => {
  const context = useContext(campaignContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function CampaignProvider({ children }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [campaignsSponsor, setCampaignsSponsor] = useState([]);
  const [campaignsClub, setCampaignsClub] = useState([]);
  const [campaignsForPayment, setCampaignsForPayment] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [purchases, setPurchases] = useState([]);
  const { token, user } = useAuth();
  const { clubID } = useAdmin();

   //@ts-ignore
   const userObject = user?.reloadUserInfo
   ? //@ts-ignore
   JSON.parse(user?.reloadUserInfo?.customAttributes)
   : null;
   const role = userObject ? userObject.role : null;

  const createCampaign = async (
    offer_id: String, sport: string, name: String, module: String, product: String, startDate: String,
    finishDate: String, audience: String, price: number, status: String, currency: String,
    eventName: String, clubID: String, sponsorID:String
  ) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/campaigns/create',
          data: { offer_id, sport, name, module, product, startDate, finishDate,
            audience, price, status, currency, eventName, clubID, sponsorID },
          headers: { "Authorization": token }
        });
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const updateCampaign = async (uid: String, offer_id: String, sport: String, name: String, module: String,
    product: String, startDate: String, finishDate: String, audience: String, price: number,
    status: String, currency: String, eventName: String, clubID: String, sponsorName: String, sponsorID: String
  ) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/campaigns/update',
          data: { uid, offer_id, sport, name, module, product, startDate, finishDate, audience,
            price, status, currency, eventName, clubID, sponsorName, sponsorID },
          headers: { "Authorization": token }
        });
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const getCampaignsByClub = async (id: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/campaigns/${id}`,
          headers: { "Authorization": token }
        }).then(response => {
          setCampaignsClub(response.data.response);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const getCampaignsBySponsor = async (admin: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/campaigns/sponsor`,
          data: { admin },
          headers: { "Authorization": token }
        }).then(response => {
          setCampaignsSponsor(response.data.response);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const getCampaignsPendingPaymentBySponsor = async (id: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/campaigns/sponsor/pending-payment`,
          data: { id },
          headers: { "Authorization": token }
        }).then(response => {
          setCampaignsForPayment(response.data.response);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const getCampaignsByOffer = async (id: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/offer/campaigns/${id}`,
          headers: { "Authorization": token }
        }).then(response => {
          setCampaigns(response.data.response);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    if (role == "sponsor") {
      getCampaignsBySponsor(user.uid);
    }
  },[user, token])

  useEffect(() => {
    if (role == "sponsor") {
      getCampaignsPendingPaymentBySponsor(user.uid);
    }
  },[user, token])

  useEffect(() => {
    //if (role == "admin") {
      getCampaignsByClub(clubID);
    //}
  },[user, token, clubID])

  return (
    <campaignContext.Provider
      value={{
        loading,
        createCampaign,
        getCampaignsByClub,
        getCampaignsBySponsor,
        getCampaignsPendingPaymentBySponsor,
        updateCampaign,
        getCampaignsByOffer,
        campaigns,
        campaignsSponsor,
        campaignsClub,
        campaignsForPayment,
        purchases,
        error
      }}
    >
      {children}
    </campaignContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
