import { createContext, useContext, useEffect, useState } from 'react';
import axios from 'axios';
import api from 'services/api';
import { useAuth } from './AuthContext';
import { useAdmin } from './adminContext';
import { IFooterSponsor, IRightSponsor, valueRequestContext } from 'content/applications/constans/request.interface';
import { noticeConfirmation } from 'content/notices/notices';
import { useTranslation } from 'react-i18next';

export const requestContext = createContext<valueRequestContext>({
  createRequest: (_idOffer: String, _right: IRightSponsor, _footer: IFooterSponsor,
    _status: String, _module: String, _product: String) => {},
  updateRequest: (_id: String, _status: String) => {},
  updateRequestGeneral: (_id: String, _product: String, _module: String) => {},
  loading: false,
  listOptions: (_clubId: String) => {},
  listRequests: (_id: String) => {},
  error: null,
  list: [],
  requests: []
});

export const useRequest = () => {
  const context = useContext(requestContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function RequestProvider({ children }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);
  const [requests, setRequests] = useState([]);
  const { token,user } = useAuth();
  const { clubID } = useAdmin();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const role = userObject ? userObject.role : null;

  const createRequest = async (
    idOffer: String, right: IRightSponsor, footer: IFooterSponsor, status: String,
    module: String, product: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/request/create',
          data: { idOffer, right, footer, status, module, product },
          headers: { "Authorization": token }
        })
        noticeConfirmation(
          t("text-notification-create-request-1"),
          t("text-notification-create-request-2"),
          "success",
          "#00E392",
          t("text-button-ok")
        );
      } catch (error) {
        setError(error);
        noticeConfirmation(
          t("text-notification-create-request-3"),
          t("text-notification-create-request-4"),
          "warning",
          "red",
          t("text-button-ok")
        );
      }
    } finally {
      setLoading(false);
    };
  };

  const updateRequest = async (id: String, status: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/request/update/${id}`,
          data: { status },
          headers: { "Authorization": token }
        })
        if(status == "Pending Payment"){
          noticeConfirmation(
            t("text-notification-update-request-1"),
            t("text-notification-update-request-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        }
        else{
          noticeConfirmation(
            t("text-notification-update-request-3"),
            t("text-notification-update-request-4"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        ;}
      } catch (error) {
        setError(error);
        noticeConfirmation(
          t("text-notification-update-request-5"),
          t("text-notification-update-request-6"),
          "warning",
          "red",
          t("text-button-ok")
        );
      }
    } finally {
      setLoading(false);
    };
  };

  const updateRequestGeneral = async (id: String, product: String, module: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/request/update/${id}`,
          data: { product, module },
          headers: { "Authorization": token }
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const listOptions = async (id: String) => {
    setLoading(true)
    try {
      try {
        const res = await axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/club/offers/${id}`,
          headers: { "Authorization": token }
        }).then(response => {
          setList(response.data.response.filter((option) => {
            const hasSoldItem = response.data.response.some(
              (item) =>
                item.product === option.product &&
                item.module === option.module &&
                item.eventName === option.eventName &&
                item.status === "Sold"
            );
            return !hasSoldItem;
          }));
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const listRequests = async (id: String) => {
    setLoading(true)
    try {
      try {
        const res = await axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + '/sponsors/club/requests',
          data: { id },
          headers: { "Authorization": token }
        }).then(response => {
          setRequests(response.data.response);
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  useEffect(() => {
    if(role !== "sponsor") {
      listRequests(clubID);
    }
  },[clubID, token])

  return (
    <requestContext.Provider
      value={{
        createRequest,
        updateRequest,
        updateRequestGeneral,
        loading,
        listOptions,
        listRequests,
        error,
        list,
        requests,
      }}
    >
      {children}
    </requestContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
