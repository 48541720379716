import React from 'react';
import { useRoutes } from 'react-router';
import routes_sponsors from './navigation/sponsor-routes';
import dtc from './navigation/dtc-routes';
import routes from './router';
import { useAuth } from './contexts/AuthContext';
import { CircularProgress } from '@mui/material';

const EntryPoint = () => {
  const { user } = useAuth();
  //@ts-ignore
  const userObject = user?.reloadUserInfo
    ? //@ts-ignore
      JSON.parse(user?.reloadUserInfo?.customAttributes)
    : null;
  const role = userObject ? userObject.role : null;
  console.log('USER in EnTRYPOINT ', role);
  const content = useRoutes(dtc);
  const sponsorContent = useRoutes(routes_sponsors);

  // if (!role) {
  //   return <CircularProgress color="success" />;
  // }

  if (role === 'sponsor') {
    return sponsorContent;
  }

  return content;
};

export default EntryPoint;
