import { useRef, useState } from 'react';

import {
  Box,
  Button,
  Hidden,
  lighten,
  List,
  ListItem,
  ListItemText,
  Popover,
  Typography
} from '@mui/material';

import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
import { useTranslation } from 'react-i18next';
import i18n from 'config/i18n';

const UserBoxButton = styled(Button)(
  ({ theme }) => `
        padding-left: ${theme.spacing(1)};
        padding-right: ${theme.spacing(1)};
`
);

const UserBoxText = styled(Box)(
  ({ theme }) => `
        text-align: left;
        padding-left: ${theme.spacing(1)};
`
);

const UserBoxLabel = styled(Typography)(
  ({ theme }) => `
        font-weight: ${theme.typography.fontWeightBold};
        color: ${theme.palette.secondary.main};
        display: block;
`
);

const UserBoxDescription = styled(Typography)(
  ({ theme }) => `
        color: ${lighten(theme.palette.secondary.main, 0.5)}
`
);

function SelectLanguage() {
  const { t } = useTranslation();
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
      <UserBoxButton color="secondary" sx={{backgroundColor:'white'}} ref={ref} onClick={handleOpen}>
        <Hidden mdDown>
          <UserBoxText>
            <UserBoxLabel variant="body1" sx={{color:'black'}}>{t("language")}</UserBoxLabel>
            <UserBoxDescription variant="body2">
            </UserBoxDescription>
          </UserBoxText>
        </Hidden>
        <Hidden smDown>
          <ExpandMoreTwoToneIcon sx={{ ml: 1, color:'black' }} />
        </Hidden>
      </UserBoxButton>
      <Popover
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <List sx={{ p: 1 }} component="nav">
          <ListItem button onClick={() => changeLanguage("es")}>
            <ListItemText primary={t("language-espanish") + " ES"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("en")}>
            <ListItemText primary={t("language-english") + " EN"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("ar")}>
            <ListItemText primary={t("language-arabic") + " AR"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("de")}>
            <ListItemText primary={t("language-german") + " DE"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("el")}>
            <ListItemText primary={t("language-greek") + " EL"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("fr")}>
            <ListItemText primary={t("language-french") + " FR"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("hi")}>
            <ListItemText primary={t("language-hindi") + " HI"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("it")}>
            <ListItemText primary={t("language-italian") + " IT"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("ja")}>
            <ListItemText primary={t("language-japanese") + " JA"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("nl")}>
            <ListItemText primary={t("language-dutch") + " NL"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("pt")}>
            <ListItemText primary={t("language-portuguese") + " PT"} />
          </ListItem>
          <ListItem button onClick={() => changeLanguage("zh")}>
            <ListItemText primary={t("language-chinese") + " ZH"} />
          </ListItem>
        </List>
      </Popover>
    </>
  );
}

export default SelectLanguage;
