import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import { useTranslation } from 'react-i18next';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const MenuItemsSponsors = () => {
  const { t } = useTranslation();
  const menuItemsSponsors: MenuItems[] = [
    {
      heading: 'digital \\ TENNIS COMUNITY',
      items: [
        {
          name: 'Product Offering',
          link: '/Product_offering',
          icon: RequestQuoteIcon
        },
      ]
    },
    {
      heading: 'Management',
      items: [
        {
          name: 'Campaigns',
          link: '/campaigns',
          icon: TableChartTwoToneIcon
        },
        {
          name: 'd\TC product request',
          link: '/product_request',
          icon: BackupTableRoundedIcon
        },
        {
          name: 'Payment',
          icon: MonetizationOnIcon,
          link: '/payment'
        }
      ]
    },
  ];

  return menuItemsSponsors;
};

export default MenuItemsSponsors;
