import { ReactNode } from 'react';

import DesignServicesTwoToneIcon from '@mui/icons-material/DesignServicesTwoTone';
import BrightnessLowTwoToneIcon from '@mui/icons-material/BrightnessLowTwoTone';
import MmsTwoToneIcon from '@mui/icons-material/MmsTwoTone';
import TableChartTwoToneIcon from '@mui/icons-material/TableChartTwoTone';
import AccountCircleTwoToneIcon from '@mui/icons-material/AccountCircleTwoTone';
import BallotTwoToneIcon from '@mui/icons-material/BallotTwoTone';
import BeachAccessTwoToneIcon from '@mui/icons-material/BeachAccessTwoTone';
import EmojiEventsTwoToneIcon from '@mui/icons-material/EmojiEventsTwoTone';
import FilterVintageTwoToneIcon from '@mui/icons-material/FilterVintageTwoTone';
import HowToVoteTwoToneIcon from '@mui/icons-material/HowToVoteTwoTone';
import LocalPharmacyTwoToneIcon from '@mui/icons-material/LocalPharmacyTwoTone';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import SettingsTwoToneIcon from '@mui/icons-material/SettingsTwoTone';
import TrafficTwoToneIcon from '@mui/icons-material/TrafficTwoTone';
import VerifiedUserTwoToneIcon from '@mui/icons-material/VerifiedUserTwoTone';
import FiberNewIcon from '@mui/icons-material/FiberNew';
import BackupTableRoundedIcon from '@mui/icons-material/BackupTableRounded';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import IconOffering from 'components/LogoMenu/iconOffering';
import IconCampaigns from 'components/LogoMenu/iconCampaigns';
import IconProduct from 'components/LogoMenu/iconProduct';
import { useTranslation } from 'react-i18next';

export interface MenuItem {
  link?: string;
  icon?: any;
  badge?: string;
  items?: MenuItem[];
  name: string;
}

export interface MenuItems {
  items: MenuItem[];
  heading: string;
}

const MenuItemsSponsor = () => {
  const { t } = useTranslation();
  const menuItems: MenuItems[] = [
    {
      heading: t("management"),
      items: [
        {
          name: t("campaigns"),
          icon: IconCampaigns,
          link: '/campaigns'
        },
        {
          name: t("dtc-product-request"),
          icon: IconProduct,
          link: '/product_request'
        },
        {
          name: t("payment"),
          icon: IconOffering,
          link: '/payment'
        }
      ]
    }
  ];

  return menuItems;
  };

export default MenuItemsSponsor;
