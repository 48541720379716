import { createContext, useContext, useState } from 'react';
import { valueDatesAppContext } from 'content/applications/constans/admin.interface';
import axios from 'axios';
import { useAuth } from './AuthContext';

export const datesAppContext = createContext<valueDatesAppContext>({
  loading: false,
  listLadders: (_id: String) => {},
  listCourts: (_id:String) => {},
  ladders: [],
  courts: [],
  error: null,
});

export const useDatesApp = () => {
  const context = useContext(datesAppContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function DatesAppProvider({ children }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const { token } = useAuth();
  const [ladders, setLadders] = useState([]);
  const [courts, setCourts] = useState([]);

  const listLadders = async (id: String) => {
    setLoading(true)
    try {
        try {
          const res = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/ladders/name-right/available/${id}`,
            headers: {"Authorization": token}
          }).then(response => {
            setLadders(response.data.response);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const listCourts = async (id: String) => {
    setLoading(true)
    try {
        try {
          const res = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/courts/name-right/available/${id}`,
            headers: {"Authorization": token}
          }).then(response => {
            setCourts(response.data.response);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  return (
    <datesAppContext.Provider
      value={{
        loading,
        listLadders,
        listCourts,
        ladders,
        courts,
        error
      }}
    >
      {children}
    </datesAppContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
