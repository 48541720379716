import { createContext, useContext, useState } from 'react';
import api from 'services/api';
import { valuePaymentContext } from 'content/applications/constans/locations.interface';
import { useAuth } from './AuthContext';
import { noticeConfirmation } from 'content/notices/notices';
import { useTranslation } from 'react-i18next';

export const paymentContext = createContext<valuePaymentContext>({
  saveCredictCard: (_userId: string, _stripetoken: string) => {},
  deleteCredictCard: (_CardID: string, _Customer: string, _UserID: string) => {},
  paymentDirect: (_Amount: Number, _Currency: string, _Description: string,
    _Customer: any, _CardID: string, _Type: string) => {},
  distributedPayment: (_Amount: number, _Currency: string, _Description: string,
    _Source: any, _Customer: any, _Destination: string, _Percent: Number,
    _campaignID: string) => {},
  setPaymentInformation: (_product: string, _module: string, _price: number,
    _currency: string, _clubID: string, _campaignID: string, _sport: string) => {},
  information: null,
  loading: false,
  error: null
});

export const usePayment = () => {
  const context = useContext(paymentContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function PaymentProvider({ children }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [information, setInformation] = useState({
    product:'', module:'', price:0, currency:'', clubID:'', campaignID:'', sport:''})
  const { token } = useAuth();

  const saveCredictCard = async (userId: string, stripetoken: string) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/payments/save-credit-card',
          data: { userId, stripetoken }
        })
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const deleteCredictCard = async (CardID: string, Customer: string, UserID: string) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/payments/delete-credit-card',
          data: { CardID, Customer, UserID }
        });
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const paymentDirect = async (Amount: Number, Currency: string, Description: string,
    Customer: any, CardID: string, Type: string) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/payments/send-general-payments',
          data: { Amount, Currency, Description,
            Customer, CardID, Type }
        });
      } catch (error) {
        setError(error);
      }
    } finally {
      setLoading(false);
    };
  };

  const distributedPayment = async (Amount: Number, Currency: string, Description: string,
    Source: any, Customer: any, Destination: string, Percent: Number, campaignID: string) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/campaigns/payment',
          data: { Amount, Currency, Description, Source, Customer, Destination, Percent,
          campaignID },
          headers: {"Authorization": token}
        });
        noticeConfirmation(
          t("text-notification-distributed-payment-1"),
          `${t("text-notification-distributed-payment-2")} ${Currency} ${Amount}`,
          "success",
          "#00E392",
          t("text-button-ok")
        );
      } catch (error) {
        setError(error);
        noticeConfirmation(
          t("text-notification-distributed-payment-3"),
          t("text-notification-distributed-payment-4"),
          "warning",
          "red",
          t("text-button-ok")
        );
      }
    } finally {
      setLoading(false);
    };
  };

  const setPaymentInformation = (product: string, module: string, price: number,
    currency: string, clubID: string, campaignID: string, sport: string) => {
    setInformation({product, module, price, currency, clubID, campaignID, sport})
  }

  return (
    <paymentContext.Provider
      value={{
        saveCredictCard,
        deleteCredictCard,
        paymentDirect,
        distributedPayment,
        setPaymentInformation,
        information,
        loading,
        error
      }}
    >
      {children}
    </paymentContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
