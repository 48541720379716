import { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { valueLocationsContext } from 'content/applications/constans/locations.interface';
import { useAuth } from './AuthContext';

export const locationContext = createContext<valueLocationsContext>({
  loading: false,
  listCountries: () => {},
  listCities: (_id: String) => {},
  error: null,
  countries: [],
  cities: []
});

export const useLocation = () => {
  const context = useContext(locationContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function LocationProvider({ children }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const { user } = useAuth();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const rol = userObject ? userObject.role : null;
  
  const listCountries = async () => {
    setLoading(true)
    try {
        try {
          api({
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/locations/countries',
          }).then(response => {
            setCountries(response.data.response.countries);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const listCities = async (id: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/locations/cities/${id}`
          }).then(response => {
            setCities(response.data.response.cities);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  useEffect(() => {
      listCountries();
  },[])

  return (
    <locationContext.Provider
      value={{
        loading,
        listCountries,
        listCities,
        error,
        countries,
        cities
      }}
    >
      {children}
    </locationContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
