import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';

export function ProtectedRoute({ children, role }) {
  const { user, loading } = useAuth();
  if (loading) return <h1>Loading</h1>;

  if (!user) return <Navigate to="/signin" />;
  return <>{children}</>;
}
