import { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { valueClubContext } from 'content/applications/constans/admin.interface';
import { useAdmin } from './adminContext';
import { useAuth } from './AuthContext';

export const clubContext = createContext<valueClubContext>({
  loading: false,
  updateLogoClub: (_id: String, _LOGO_CLUB: string) => {},
  getClubesByCity: (_city: String) => {},
  getClub: (_club_ID: String) => {},
  getClubes: () => {},
  totalClubes: [],
  club: null,
  clubes: [],
  error: null,
});

export const useClub = () => {
  const context = useContext(clubContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function ClubProvider({ children }) {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clubes, setClubes] = useState([]);
  const [totalClubes, setTotalClubes] = useState([]);
  const [club, setClub] = useState(null);
  const { clubID } = useAdmin();
  const { user } = useAuth();

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const role = userObject ? userObject.role : null;

  const updateLogoClub = async (id: String, LOGO_CLUB: string) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/clubes/update-settings',
            data: { id, LOGO_CLUB }
          });
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const getClubesByCity = async (city: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + `/clubes/clubes-by-city`,
            data: { city }
          }).then(response => {
            setClubes(response.data.response.clubes)
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const getClub = async (club_ID: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/clubes/${club_ID}`
          }).then(response => {
            setClub(response.data.response)
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const getClubes = async () => {
    setLoading(true)
    try {
      api({
        method: 'get',
        url: process.env.REACT_APP_API_URL + `/clubes/all/clubes`
      }).then(response => {
        setTotalClubes(response.data.response.clubes)
      })
    } catch (error) {
      setError(error);
    }
  };
  
  useEffect(() => {
    if (role == "admin") {
      getClub(clubID);
    }
  },[user, clubID])

  useEffect(() => {
    getClubes();
  },[])

  return (
    <clubContext.Provider
      value={{
        loading,
        updateLogoClub,
        getClubesByCity,
        getClub,
        getClubes,
        totalClubes,
        club,
        clubes,
        error
      }}
    >
      {children}
    </clubContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
