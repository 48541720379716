import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import { PartialRouteObject } from 'react-router';
import NavbarLayout from 'layouts/NavbarLayout';
import SuspenseLoader from 'components/SuspenseLoader';
import StatusLayout from 'layouts/StatusLayout';
import NewSidebarLayout from 'layouts/NewSidebarLayout';
import { ProtectedRoute } from 'navigation/protected-routes';

const Loader = (Component) => (props) =>
  (
    <Suspense fallback={<SuspenseLoader />}>
      <Component {...props} />
    </Suspense>
  );

// Dashboards
const Terms = Loader(lazy(() => import('content/applications/Users/Terms')));
const Purchase = Loader(lazy(() => import('content/ClubViews/PurchaseRequest')));
const SignIn = Loader(lazy(() => import('content/signin/sign-in.component')));
const SignUp = Loader(lazy(() => import('content/signup/sign-up.component')));
const CampaignsDtc = Loader(lazy(() => import('content/applications/CampaignsDTC')));
const ProductOffering = Loader(lazy(() => import('content/applications/ProductOffering')));
const RecoveryEmail = Loader(
  lazy(() => import('content/applications/Configurations/RecoveryEmail'))
);
const Password = Loader(
  lazy(() => import(
    'content/applications/Configurations/PasswordModification/PasswordRecovery'
  ))
);
const UserProfileDTC = Loader(
  lazy(() => import('content/applications/Users/profile/indexDTC'))
);
const UserSettingsDTC = Loader(
  lazy(() => import('content/applications/Users/settings/indexDTC'))
);

// Status
const Status404 = Loader(lazy(() => import('content/pages/Status/Status404')));
const Status500 = Loader(lazy(() => import('content/pages/Status/Status500')));
const StatusMaintenance = Loader(
  lazy(() => import('content/pages/Status/Maintenance'))
);

const routes: PartialRouteObject[] = [
  {
    path: '*',
    element: <NavbarLayout />,
    children: [
      {
        path: 'signin',
        element: <SignIn />
      },
      {
        path: 'signup',
        element: <SignUp />
      },
      {
        path: 'recovery_Email',
        element: <RecoveryEmail />
      },
      {
        path: 'password_recovery',
        element: <Password />
      },
      {
        path: 'terms_and_conditions',
        element: <Terms />
      }
    ]
  },
  {
    path: '*',
    element: <StatusLayout />,
    children: [
      {
        path: 'status',
        children: [
          {
            path: '/',
            element: <Navigate to="404" replace />
          },
          {
            path: '404',
            element: <Status404 />
          },
          {
            path: '500',
            element: <Status500 />
          },
          {
            path: 'maintenance',
            element: <StatusMaintenance />
          }
        ]
      },
      {
        path: '*',
        element: <Status404 />
      }
    ]
  },
  {
    path: '/',
    element: (
      <ProtectedRoute role="sponsor">
        <NewSidebarLayout />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'campaigns',
        element: <CampaignsDtc />
      },
      {
        path: 'purchase_requests',
        element: <Purchase />
      },
      {
        path: 'product_offering',
        element: <ProductOffering />
      },
      {
        path: 'profile',
        children: [
          {
            path: 'details',
            element: <UserProfileDTC />
          },
          {
            path: 'settings',
            element: <UserSettingsDTC />
          }
        ]
      }
    ]
  }
];

export default routes;
