import { Box, Tooltip } from '@mui/material';
import logo from './LogoHorizontal_Transparente.png';

function Logo() {
  return (
    <Tooltip title="" arrow>
      <Box sx={{marginLeft: 5}}>
        <img src={logo} width="150" height="50" alt="logo" />
      </Box>
    </Tooltip>
  );
}

export default Logo;
