import { createContext, useContext, useState } from 'react';
import api from 'services/api';
import { valueEmailContext } from 'content/applications/constans/email.interface';

export const emailContext = createContext<valueEmailContext>({
  createEmail: (email: string, template: string, body: string, language: string) => {},
  error: null,
});

export const useEmail = () => {
  const context = useContext(emailContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function EmailProvider({ children }) {
  const [error, setError] = useState(null);

  const createEmail = async (email: String, template: String, body: String, language: String) => {
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/emails/send',
          data: { email, template, body, language }
        })
      } catch (error) {
        setError(error);
      }
    } finally {};
  };

  return (
    <emailContext.Provider
      value={{
        createEmail,
        error,
      }}
    >
      {children}
    </emailContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
