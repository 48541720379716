import { Helmet } from 'react-helmet-async';
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PageHeader from './PageHeader';
import PageTitleWrapper from 'components/PageTitleWrapper';
import Footer from 'components/Footer';
import PaymentConfirmation from './paymentConfirmation';
import logo from 'components/LogoSign/tapps_bluecourt_telonweb.png';

const MainContent = styled(Box)(
  ({ theme }) => `
  margin-top: auto;
  flex: 1 1 auto;
  background-image: url(${logo});
  background-size: cover;
`
);

function PaymentMode() {

  return (
    <MainContent>
      <Helmet>
        <title>Payment</title>
      </Helmet>
      <PageTitleWrapper>
        <PageHeader />
      </PageTitleWrapper>
      <Container>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <PaymentConfirmation/>
            </CardContent>
          </Card>
        </Grid>
      </Container>
      <Footer />
    </MainContent>
  );
}

export default PaymentMode;
