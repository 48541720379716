import { useContext, useState } from 'react';

import { Box, Button, Dialog, Grid, Hidden, Icon, IconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import MenuTwoToneIcon from '@mui/icons-material/MenuTwoTone';
import { SidebarContext } from 'contexts/SidebarContext';
import CloseTwoToneIcon from '@mui/icons-material/CloseTwoTone';

import HeaderUserbox from './Userbox';
import Logo from 'components/LogoSign';
import logo from 'components/LogoSign/tapps_bluecourt_telonheaderweb.png';
import logo1 from 'components/LogoMenu/Tappssponsors_logo.png';
import SelectLanguage from 'content/selectLanguage';
import ReactPlayer from 'react-player';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import { useTranslation } from 'react-i18next';

const HeaderWrapper = styled(Box)(
  ({ theme }) => `
        height: ${theme.header.height};
        color: ${theme.header.textColor};
        padding: ${theme.spacing(0, 2)};
        right: 0;
        z-index: 5;
        background-image: url(${logo});
        background-size: cover;
        box-shadow: ${theme.header.boxShadow};
        position: fixed;
        justify-content: space-between;
        width: 100%;
        @media (min-width: ${theme.breakpoints.values.lg}px) {
            left: ${theme.sidebar.width};
            width: auto;
        }
`
);

function Header() {
  const { t } = useTranslation();
  const { sidebarToggle, toggleSidebar } = useContext(SidebarContext);
  const [open, setOpen] = useState(false);

  const tutorial = `${process.env.PUBLIC_URL}/videos/${t("language-code")}/SponsorsFunctionalities.mp4`;

  const handleOpen = () => {
    setOpen(true);
  }

  const handleClose = () => {
    setOpen(false);
  }

  return (
    <HeaderWrapper display="flex" alignItems="center">
      <Box display="flex" alignItems="flex-start">
        <SelectLanguage/>
      </Box>
      <Box display="flex" alignItems="center">
        <Hidden lgUp>
          <Logo />
        </Hidden>
      </Box>
      <Box display="flex" alignItems="center">
        <Box sx={{paddingInline: 10}}>
          <img src={logo1} width="400" height="100" alt="logo" />
        </Box>
        <Grid style={{display: 'flex', alignItems: 'center'}} onClick={handleOpen}>
          <Icon style={{ width: 30, height: 30 }}>
            <PlayArrowIcon style={{
              color: 'red', backgroundColor: 'white', borderRadius: 10, width: 20, height: 20
            }} />
          </Icon>
          <div style={{ color: 'white', fontWeight: 'bold', padding: 0 }}>{t("tutorial")}</div>
        </Grid>
        <Dialog
          open={open}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
          fullWidth={true}
        >
          <ReactPlayer 
            controls
            url={tutorial}
            width='100%'
            height='auto'
          />
          <footer>
            <Button
            style={{height:50,
              width:'100%',
              backgroundColor: "#C9F305",
              display: "flex",
              justifyContent: "center",
              alignItems: "center"}}
            onClick={handleClose}
            variant="outlined">Back</Button>
          </footer>  
        </Dialog>
        <HeaderUserbox />
        <Hidden lgUp>
          <Tooltip arrow title="Toggle Menu">
            <IconButton color="primary" onClick={toggleSidebar}>
              {!sidebarToggle ? <MenuTwoToneIcon /> : <CloseTwoToneIcon />}
            </IconButton>
          </Tooltip>
        </Hidden>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;
