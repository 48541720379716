import { createContext, useContext, useEffect, useState } from 'react';
import api from 'services/api';
import { useAuth } from './AuthContext';
import { valueAdminContext } from 'content/applications/constans/admin.interface';
import axios from 'axios';
import { noticeConfirmation } from 'content/notices/notices';
import { useTranslation } from 'react-i18next';

export const adminContext = createContext<valueAdminContext>({
  loading: false,
  getAdminClub: (_uid: String) => {},
  updateSponsorAvatar: (_uid: String, _avatar: String) => {},
  updateSponsor: (_uid: String, _city: String, _company: String, _country: String,
    _industry: String, _phone: Number, _company_description: String,
    _position_admin: String) => {},
  getSponsor: (_sponsorID: String) => {},
  sponsor: null,
  clubID: null,
  club: null,
  error: null,
});

export const useAdmin = () => {
  const context = useContext(adminContext);
  if (!context) throw new Error('There is no Auth providers');
  return context;
};

export function AdminProvider({ children }) {
  const { t } = useTranslation();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clubID, setClubID] = useState('');
  const { token, user } = useAuth();
  const [sponsor, setSponsor] = useState({city:'', company:'', country:'', industry:'',
  phone: 0, company_description:'', position_admin:'', avatar:''});
  const [club, setClub] = useState({id: '', city: '', country: '', connctedAccountIdStripe: '', 
    currency: '', name: '', phone: '', paymentConnect: false, useStripe: false, LOGO_CLUB: '',});

  //@ts-ignore
  const userObject = user?.reloadUserInfo
  ? //@ts-ignore
  JSON.parse(user?.reloadUserInfo?.customAttributes)
  : null;
  const role = userObject ? userObject.role : null;

  const getAdminClub = async (uid: String) => {
    setLoading(true)
    try {
        try {
          const res = await axios({
            method: 'get',
            url: process.env.REACT_APP_API_URL + `/clubes/get-admin-data/${uid}`
          }).then(response => {
            setClubID(response.data.response.club.id);
            setClub(response.data.response.club);
          })
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const updateSponsorAvatar = async (uid: String, avatar: String) => {
    setLoading(true)
    try {
      try {
        api({
          method: 'post',
          url: process.env.REACT_APP_API_URL + '/sponsors/update',
          data: { uid, avatar },
          headers: {"Authorization": token}
        })
      } catch (error) {
        setError(error);
      }
      } finally {
        setLoading(false);
      };
  };

  const updateSponsor = async (uid: String, city: String, company: String, country: String,
    industry: String, phone: Number, company_description: String,
    position_admin: String) => {
    setLoading(true)
    try {
        try {
          api({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/sponsors/update',
            data: { uid, city, company, country, industry, phone, company_description,
              position_admin },
            headers: {"Authorization": token}
          });
          noticeConfirmation(
            t("text-notification-update-sponsor-2"),
            user.displayName + ", " + t("text-notification-update-sponsor-2"),
            "success",
            "#00E392",
            t("text-button-ok")
          );
        } catch (error) {
          setError(error);
        }
      } finally {
        setLoading(false);
      };
  };

  const getSponsor = async (sponsorID: String) => {
    setLoading(true);
    try {
      try {
        const res = await axios({
          method: 'get',
          url: process.env.REACT_APP_API_URL + `/sponsors/${sponsorID}`,
          headers: {"Authorization": token}
        }).then(response => {
          setSponsor(response.data.response);
        })
      }
      catch (error) {
        setError(error.response?.data?.response?.msg);
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if(role == "admin") {
      getAdminClub(user.uid);
    }
  },[token, user])

  useEffect(() => {
    if(token && (role == "sponsor")) {
      getSponsor(user.uid);
    }
  },[user, token])

  return (
    <adminContext.Provider
      value={{
        loading,
        getAdminClub,
        updateSponsorAvatar,
        updateSponsor,
        getSponsor,
        sponsor,
        clubID,
        club,
        error
      }}
    >
      {children}
    </adminContext.Provider>
  );
}
function then(arg0: any) {
  throw new Error('Function not implemented.');
}
